<template>
  <div>
    <div class="d-flex mb-2">
      <span>{{ $t("t_config_color_chart") }}</span>
      <div @click="addConfig" class="btn-add d-flex">
        <i class="ri-add-circle-line" style="margin-right: 5px"></i>
        {{ $t("t-add") }}
      </div>
      <el-checkbox
        v-if="
          props.typeChart === 'chart-bar-vert-single' ||
          props.typeChart === 'chart-bar-hori-single'
        "
        v-model="multiColors"
        style="margin-left: 30px"
        @change="changeTypeMultiColors"
        :label="`${$t('t_multi_color_for_single_bar_chart')}`"
      />
    </div>

    <div class="config-group" v-if="configs.value?.length > 0">
      <b-row class="config-container">
        <b-col
          md="12"
          v-for="(config, index) in configs.value"
          :key="index"
          class="config-item"
        >
          <div class="color-configs">
            <el-row>
              <el-col
                :sm="6"
                v-if="
                  props.typeChart !== 'chart-radar' &&
                  props.typeChart !== 'chart-line-single' &&
                  props.typeChart !== 'chart-line-multi'
                "
              >
                <span>Gradient</span>
                <el-switch
                  v-model="config.gradient"
                  @change="changeOptionGradient(index)"
                />
              </el-col>
              <el-col :sm="6">
                <span>{{ $t("t_line_border_color") }}</span>
                <el-color-picker v-model="config.line_color" />
              </el-col>
              <el-col
                :sm="6"
                v-if="
                  props.typeChart !== 'chart-radar' &&
                  props.typeChart !== 'chart-line-single' &&
                  props.typeChart !== 'chart-line-multi'
                "
              >
                <span>{{
                  config.gradient === false
                    ? $t("t_bg_color")
                    : $t("t_first_color")
                }}</span>
                <el-color-picker v-model="config.bg_color.color" />
              </el-col>
              <el-col
                :sm="6"
                v-if="
                  config.gradient &&
                  props.typeChart !== 'chart-radar' &&
                  props.typeChart !== 'chart-line-single' &&
                  props.typeChart !== 'chart-line-multi'
                "
              >
                <span>{{ $t("t_end_color") }}</span>
                <el-color-picker v-model="config.bg_color.to_color" />
              </el-col>

              <el-col
                :sm="8"
                class="color-configs__radar"
                v-show="props.typeChart === 'chart-radar'"
              >
                <span>{{ $t("t_rada_max") }}</span>
                <el-input-number
                  v-model="config.radar_max"
                  :step="1"
                  step-strictly
                  :placeholder="`${$t('t-placeholder-radar')}`"
                  controls-position="right"
                />
              </el-col>
            </el-row>
            <div
              class="color-configs__link"
              :class="{ isHaveRadar: props.typeChart === 'chart-radar' }"
              v-show="
                props.typeChart.includes('chart') &&
                props.typeChart !== 'chart-radar' &&
                props.typeChart !== 'chart-line-single' &&
                props.typeChart !== 'chart-line-multi' &&
                props.typeChart !== 'chart-line-area-single' &&
                props.typeChart !== 'chart-line-area-multi'
              "
            >
              <span>Link</span>
              <div class="color-configs__linkInput">
                <el-input
                  v-model="config.link"
                  :placeholder="`${$t('t-placeholder-link-chart-column')}`"
                  clearable
                  @change="checkValidateLink"
                />
                <p v-show="!config.validateLink">
                  {{ $t("t_link_dont_includes#") }}
                </p>
              </div>
            </div>
            <div class="color-configs__dashboardPopup">
              <p>{{ $t("t_place_select_dashboard_popup") }}</p>
              <div>
                <el-select
                  v-model="config.popup.popup_id"
                  :placeholder="`${$t('t_place_select_dashboard_popup')}`"
                  filterable
                  clearable
                  @clear="config.popup.popup_id = undefined"
                  style="width: 300px"
                >
                  <el-option
                    v-for="item in props.proplistDashboardPopupFromCardItemConfig"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </div>
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="`${$t('t_reload_list_dashboard_popup')}`"
                placement="top"
              >
                <el-button
                  plain
                  style="margin: 0 10px 0 15px"
                  @click="updateListDashboardPopup"
                  ><i class="ri-refresh-line"></i
                ></el-button>
              </el-tooltip>
              <el-tooltip
                class="box-item"
                effect="dark"
                :content="`${$t('t_add_dashboard_popup')}`"
                placement="top"
              >
                <el-link
                  :href="`${locationHref + '/dashboard-popup'}`"
                  target="_blank"
                  ><el-button type="primary" plain
                    ><i class="ri-add-fill"></i></el-button
                ></el-link>
              </el-tooltip>
            </div>

            <el-tooltip effect="light" content="Xóa" placement="top">
              <el-button
                circle
                class="box-item"
                type="danger"
                size="small"
                @click="removeConfig(index)"
                ><i class="ri-close-line"></i></el-button
            ></el-tooltip>
          </div>
        </b-col>
      </b-row>

      <div class="confirm-configs">
        <el-button
          @click="acceptConfig"
          size="small"
          type="success"
          :loading="loadingBtn"
          >{{ $t("t-confirm") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, reactive, watch, ref, onMounted } from "vue";
const emit = defineEmits([]);
const props = defineProps({
  colorConfigIn: Array,
  typeChart: String,
  isMultiColors: Boolean,
  proplistDashboardPopupFromCardItemConfig: Array,
});
const configs = reactive({ value: props.colorConfigIn });
const loadingBtn = ref(false);
const validateLinksConfig = ref(true);
const multiColors = ref(props.isMultiColors);
const locationHref = ref(window.origin);
const changeTypeMultiColors = (e) => emit("multiColors", e);
const addConfig = () => {
  configs.value.push({
    gradient: true,
    bg_color: {
      color: "",
      to_color: "",
    },
    line_color: "",
    radar_max: "",
    link: "",
    popup: {
      popup_id: "",
    },
    validateLink: true,
  });
};
const updateListDashboardPopup = () => {
  emit("updateListDashboardPopup");
};
const checkValidateLink = () => {
  validateLinksConfig.value = true;
  if (configs.value?.length > 0) {
    configs.value.forEach((config) => {
      if (config?.link.includes("#")) {
        config.validateLink = false;
        validateLinksConfig.value = false;
        return;
      } else config.validateLink = true;
    });
  }
  validateLinksConfig.value
    ? emit("colorConfigOut", configs)
    : emit("colorConfigOut", null);
};
const changeOptionGradient = (index) => {
  configs.value[index].bg_color.to_color = "";
  emit("colorConfigOut", configs);
};
const acceptConfig = () => {
  loadingBtn.value = true;
  checkValidateLink();
  setTimeout(() => {
    loadingBtn.value = false;
  }, 1000);
};
const removeConfig = (index) => {
  configs.value.splice(index, 1);
  emit("colorConfigOut", configs);
};
watch(
  () => props.colorConfigIn,
  (newData) => {
    newData.forEach((item) => {
      item.validateLink = true;
      item.gradient = !item.bg_color.to_color ? false : true;
    });
    configs.value = newData;
  }
);
onMounted(() => {
  props.colorConfigIn.forEach((item) => {
    item.validateLink = true;
    item.gradient = !item.bg_color.to_color ? false : true;
  });
  configs.value = props.colorConfigIn;
});
</script>
<style scoped lang="scss">
.btn-add {
  background-color: #409eff;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  padding: 0px 7px;
  margin-left: 50px;
  transition: 0.2s;
}

.btn-add:hover {
  background-color: rgba(64, 160, 255, 0.849);
}

.config-group {
  border-radius: 3px;
  border: thin solid #dcdcdc;
  margin: 0 0 15px 0;
  padding: 0 0 7px 0;
}

.config-group span {
  margin-right: 10px;
}

.config-container {
  margin: 15px 15px 0 15px;
}

.confirm-configs {
  text-align: center;
}

.config-item {
  margin-bottom: 15px;
  border: thin dashed #cdcdcd;
  border-radius: 3px;
  padding: 10px 0;
  position: relative;
}

.color-configs {
  padding: 0 15px;

  &__link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__linkInput {
    width: 100%;
    position: relative;

    p {
      color: #f06548;
      font-size: 11px;
      margin-bottom: 0;
      line-height: normal;
      margin-top: 1px;
    }
  }

  &__dashboardPopup {
    display: flex;
    padding-top: 15px;
    align-items: center;

    p {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }

  &__radar {
    display: flex;
    justify-content: space-between;
  }

  &__linkRadar {
    display: flex;
    justify-content: space-between;
  }

  .isHaveRadar {
    width: calc(100% - 350px);
  }
}

.box-item {
  position: absolute;
  right: -11px;
  top: -11px;
}

.el-link.is-underline:hover:after {
  border-bottom: none;
}
</style>
