<template>
  <el-dialog
    v-model="dialogVisible"
    :title="nameDashboardPopup"
    width="80%"
    :close-on-click-modal="false"
    :align-center="true"
    :before-close="handleClose"
  >
    <div class="text-center no-card" v-if="dataDashboardPopup.length === 0">
      <i
        style="
          font-size: 40px;
          opacity: 0.5;
          color: #e0ba00;
          margin-right: 10px;
        "
        class="ri-bank-card-line"
      ></i>
      <span>{{ $t("t-no-card") }}</span>
    </div>
    <div class="dialog-dashboard" v-if="dataDashboardPopup.length > 0">
      <div v-for="item in dataDashboardPopup" :key="item.id">
        <dashboard-card
          :cardId="item.id"
          :headerOptions="item?.header"
          :cardTitle="item.title"
          :key="KeyReRenderCard"
          @reRenderCard="reRenderCard"
          @reloadGridDashboard="reloadGridDashboard"
        ></dashboard-card>
      </div>
    </div>

    <div
      class="add-card d-flex justify-content-end"
      v-if="storeChangeEdit.showEdit === true"
    >
      <el-button
        @click="addCard"
        type="button"
        style="height: 40px"
        class="btn btn-secondary btn-border"
      >
        {{ $t("t-add-card") }}
      </el-button>
    </div>
  </el-dialog>
</template>
<script setup>
import { defineEmits, defineProps, ref, watch } from "vue";
import mushroom from "mushroomjs";
import { Filter } from "mushroomjs";
import DashboardCard from "@/components/DashboardCardComponent.vue";
import MethodService from "@/service/MethodService";
import toastr from "toastr";
import Swal from "sweetalert2";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
import { changeEdit } from "@/stores/changeEdit";
const storeChangeEdit = changeEdit();
const emit = defineEmits(["closeDialog"]);
const props = defineProps({
  idPopupDashboard: String,
  isShowDialog: Boolean,
});
const dialogVisible = ref(false);
const dataDashboardPopup = ref([]);
const indexCard = ref(0);
const titleCard = ref("");
const nameDashboardPopup = ref("");
const KeyReRenderCard = ref(0);
const handleClose = () => {
  emit("closeDialog");
  dataDashboardPopup.value = [];
  dialogVisible.value = false;
};
const getNameDashboardPopup = async (id) => {
  try {
    const response = await mushroom.dashboard_popup.findByIdAsync({
      id: id,
    });
    if (response.result) nameDashboardPopup.value = response.result.name;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getAllCard = async () => {
  dataDashboardPopup.value = [];
  try {
    const response = await mushroom.card.listAsync({
      filters: Filter.eq("popup_id", props.idPopupDashboard).build(),
      sort: "index",
    });

    if (response.result?.length > 0) dataDashboardPopup.value = response.result;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const addCard = () => {
  const addDomCard = {
    id: Math.random() + "",
  };
  Swal.fire({
    title: t("t_input_card_title"),
    input: "text",
    inputAttributes: {
      autocapitalize: "off",
    },
    confirmButtonText: t("t-add"),
    icon: "info",
    showLoaderOnConfirm: true,
    cancelButtonText: t("t-cancel"),
    showCancelButton: true,
    allowOutsideClick: false,
    preConfirm: (title) => {
      if (!title) {
        Swal.showValidationMessage(t("swal_please_input_title_name"));
      } else {
        titleCard.value = title;
        indexCard.value = indexCard.value + 1;
        addDomCard.title = title;
      }
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      await saveCard(titleCard.value, indexCard.value, props.idPopupDashboard);
      await getAllCard();
    }
  });
};
const saveCard = async (titleCard, indexCard, idDashboardPopup) => {
  const card_object = {
    dashboard: "popup",
    title: titleCard,
    index: indexCard,
    popup_id: idDashboardPopup,
    header: {
      is_hidden: true,
    },
  };
  try {
    const newId = await mushroom.card.createAsync(card_object);
    if (newId) toastr.success(t("toastr_add_success"));
  } catch (e) {
    MethodService.showError(e.code);
  }
};
const reRenderCard = () => {
  //render lại card khi xoá 1 card-items
  KeyReRenderCard.value += 1;
};
const reloadGridDashboard = () => {
  getAllCard();
};
// onMounted(async () => {
//   dataDashboardPopup.value = []
//   await getNameDashboardPopup(props.idPopupDashboard)
//   await getAllCard()
//   // dialogVisible.value = props.isShowDialog
// })
watch(
  () => props.isShowDialog,
  async (isShow) => {
    if (isShow) {
      dataDashboardPopup.value = [];
      await getNameDashboardPopup(props.idPopupDashboard);
      await getAllCard();
      dialogVisible.value = isShow ? true : false;
    }
  }
);
</script>
<style scoped>
.dialog-dashboard {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
