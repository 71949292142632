<template>
  <div
    :id="'cem-card-item-inner-' + props.item.id"
    style="width: 100%; height: 100%"
  >
    <div
      v-if="dataTable?.data?.length > 0"
      class="table-body"
      :class="{ noCenter: config_paging }"
    >
      <el-table
        :data="dataTable.data"
        style="width: 100%"
        :id="'table-' + props.item.id"
        :class="{
          height100: dataTable.data.length * 44 >= heightTable,
        }"
        class="table-component"
        v-loading="loading"
        @cell-dblclick="dblclickCell"
      >
        <el-table-column
          v-for="(col, index) in dataTable.columns"
          :key="col.index"
          :prop="col.colForSort"
          :label="col.title_name ? col.title_name : col.title"
          :min-width="col.width || 180"
          align="center"
        >
          <template #header>
            <div class="header" :style="`justify-content: ${col.align}`">
              <div v-if="col.icon?.visible">
                <img
                  v-if="col.icon?.file_id?.length > 0"
                  :src="renderIconColumns(col.icon?.file_id)"
                  alt="icon column"
                />
                <img v-else src="@/assets/icon/calendar.svg" alt="calendar" />
              </div>
              <el-tooltip
                v-if="col.tooltip"
                class="box-item"
                effect="dark"
                :content="col.tooltip"
                placement="top"
              >
                <span class="header-tooltip">
                  {{ col.title_name ? col.title_name : col.title }}
                </span>
              </el-tooltip>
              <span v-else class="header-tooltip">
                {{ col.title_name ? col.title_name : col.title }}
              </span>
              <div v-if="col.sort" class="table-component__sort">
                <i
                  class="ri-arrow-up-s-fill"
                  :class="'sortTable_' + props.item.id"
                  @click="sortTable('asc', col)"
                  :id="col.code + '_' + props.item.id + '_asc'"
                ></i>
                <i
                  class="ri-arrow-down-s-fill"
                  :class="'sortTable_' + props.item.id"
                  :id="col.code + '_' + props.item.id + '_desc'"
                  @click="sortTable('desc', col)"
                ></i>
              </div>
            </div>
          </template>
          <template #default="scope">
            <div
              v-if="
                scope.row[index] !== null &&
                scope.row[index] !== undefined &&
                scope.row[index] !== ''
              "
              class="value"
            >
              <p
                style="margin-bottom: 0px"
                v-if="
                  col.type != 'rate' &&
                  col.type != 'progress' &&
                  col.data_type == 'DateTime'
                "
              >
                {{ MethodService.formatDate(scope.row[index]) }}
              </p>
              <p
                :style="`margin-bottom: 0px;${
                  col.color ? 'color: ' + col.color + '; ' : ''
                }${col.align ? 'text-align: ' + col.align + '; ' : ''}${
                  col.fontSize ? 'font-size: ' + col.fontSize + 'px; ' : ''
                }`"
                :class="{
                  textBold: col.textBold,
                  textItalic: col.textItalic,
                  textUnderline: col.textUnderline,
                }"
                v-if="
                  col.type != 'rate' &&
                  col.type != 'progress' &&
                  (!col.data_type || col.data_type !== 'DateTime')
                "
              >
                <span v-if="col.isHtml" v-html="scope.row[index]"></span>
                <span v-if="!col.isHtml">{{ scope.row[index] }}</span>
              </p>

              <el-rate
                v-model="scope.row[index]"
                v-if="col.type == 'rate'"
                disabled
                allow-half
              />
              <p
                style="margin-bottom: 0px; width: 100%"
                v-if="col.type == 'progress'"
              >
                {{ scope.row[index] }}%
              </p>
              <el-progress
                :show-text="false"
                :percentage="scope.row[index]"
                :color="col.color"
                v-if="col.type == 'progress'"
                :stroke-width="6"
                style="width: 60%"
              >
              </el-progress>
            </div>
            <div v-else>
              <span>-</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else></div>
  </div>
</template>

<script setup>
import * as moment from "moment";
import { defineEmits, defineProps, reactive, watch, ref } from "vue";
import mushroom from "cem-probe-api";
import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";
import MethodService from "@/service/MethodService";

const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  configTable: Array,
  isLoading: Boolean,
  configPaging: Boolean,
  heightCardContent: Number,
});
const emit = defineEmits([]);
const loading = ref(false);
const config_paging = ref(props.configPaging);
const dataTable = reactive({ data: [], columns: [] });
const tableRules = {
  total: 0,
  page: 1,
  limit: 20,
  offset: 0,
  sort: null,
  sort_type: null,
};
let heightTable = ref(0);
window.fnPools = {
  fnRenderReAuthen: MethodService.fnRenderReAuthen,
  fnRenderSpeedTest: MethodService.fnRenderSpeedTest,
  fnRenderService: MethodService.fnRenderService,
  fnRenderUC53: MethodService.fnRenderUC53,
  fnRenderUsedHour: MethodService.fnRenderUsedHour,
  fnRenderServerIP: MethodService.fnRenderServerIP,
  fnRenderFailCustNumber: MethodService.fnRenderFailCustNumber,
};
const dblclickCell = (row, column) => {
  if (props.configTable?.length === 0) return;
  const id =
    props.configTable.filter(
      (configCol) => configCol.field_name === column.label
    )[0]?.popup?.popup_id || undefined;
  const index = props.configTable.findIndex(
    (configCol) => configCol.field_name === column.label
  );
  if (!id || !index) return;

  emit(
    "showDialogDashboardPopup",
    id,
    MethodService.renderLinkChart(
      null,
      props.configTable[index]?.code,
      row[index],
      localStorage.getItem("urlOld")
        ? localStorage.getItem("urlOld")
        : location.href
    )
  );
};
const renderIconColumns = (iconId) => {
  return mushroom.$file.linkBuilder.thumb.id(iconId).build();
};
const sortTable = (type, col) => {
  loading.value = true;
  setTimeout(() => {
    let domAll = document.querySelectorAll(
      `.table-component__sort i.sortTable_${props.item.id}`
    );
    let domTypeSort = document.getElementById(
      col.code + "_" + props.item.id + "_" + type
    );
    if (domTypeSort) {
      if (domAll) {
        domAll.forEach((dom) => {
          if (dom !== domTypeSort) dom.classList.remove("active");
        });
      }
      if (domTypeSort.classList.contains("active")) {
        domTypeSort.classList.remove("active");
        tableRules.sort = null;
        tableRules.sort_type = null;
      } else {
        domTypeSort.classList.add("active");
        tableRules.sort = col.field_name;
        type === "asc"
          ? (tableRules.sort_type = "asc")
          : (tableRules.sort_type = "desc");
      }
    }

    emit("getDataTableWhenSort", tableRules);
  }, 100);
};
watch(
  () => props.configPaging,
  (newConfigPaging) => {
    config_paging.value = newConfigPaging;
  }
);
watch(
  () => props.isLoading,
  (newLoading) => {
    loading.value = newLoading;
  }
);
watch(
  () => props.heightCardContent,
  (newHeight) => {
    heightTable.value = newHeight;
  }
);
watch(
  () => props.itemData,
  (newData) => {
    setTimeout(() => {
      const idDom = "cem-card-item-inner-" + props.item.id;
      const dom = document.querySelector(`#${idDom} .table-body`);
      if (dom) heightTable.value = dom.offsetHeight;
    }, 500);

    if (props.configTable.length == 0) {
      dataTable.columns = newData.columns;

      newData.data?.forEach((itemData) => {
        newData.columns?.forEach((col, index) => {
          if (col.type == "rate") {
            if (itemData[index] == 1) {
              return 1.5;
            }
            if (itemData[index] == 5) {
              return 4.5;
            }
          }
        });
      });
      dataTable.data = newData.data;
    } else {
      dataTable.columns.length = 0;
      props.configTable?.forEach((item) => {
        if (!item.visible) return;

        const col =
          newData?.columns?.find((c) => c.title == item.field_name) ?? {};

        col.title_name = item.title_name;
        col.field_name = item.field_name;
        col.textBold = item.bold;
        col.textItalic = item.italic;
        col.textUnderline = item.underline;
        col.color = item.color;
        col.align = item.align;
        col.fontSize = item.font_size;
        col.type = item.type || "none";
        col.visible = item.visible;
        col.cellLink = item.cellLink || undefined;
        col.formatString = item.formatString;
        col.isHtml = item.cellLink ?? !!col.formatString;
        col.width = item.width;
        col.sort = item.sort?.is_allowed || false;
        col.icon = {
          visible: item.icon?.visible || false,
          file_id: item.icon?.file_id || "",
        };
        col.tooltip = item.tooltip;

        if (item.formatString) {
          col.colForSort = item.formatString.slice(
            item.formatString.indexOf("{") + 1,
            item.formatString.lastIndexOf("}")
          );
        } else col.colForSort = col.title;
        dataTable.columns.push(col);
      });
      dataTable.data = newData?.data?.map((row) => {
        return dataTable.columns.map((col) => {
          let formatString = undefined;

          if (col.cellLink) {
            let index = -1;
            newData.columns.some((item, idx) => {
              if (item.field_name === col.cellLink.valueColumn) {
                index = idx;
                return true;
              }
              return false;
            });

            if (index !== -1) {
              let value = row[index];
              if (
                value === null ||
                value === undefined ||
                value === "NA" ||
                value === "-" ||
                value === ""
              )
                return undefined;

              if (typeof value === "number") value = String(row[index]);
              const link = col.cellLink.links.find((x) =>
                x.values.includes(value)
              );

              formatString = link
                ? link.formatString
                : col.cellLink.defaultLink?.formatString;
            }
          }

          if (!formatString) {
            formatString = col.formatString;
          }

          // %=fnRenderReAuthen%
          // ^%=[a-zA-Z0-9_]+%$
          if (col.formatString?.match(/^%=[a-zA-Z0-9_]+%$/)) {
            let fnName = col.formatString.replace(/%/g, "").replace("=", "");
            const fn = window.fnPools[fnName];
            if (!fn) return "";

            let index = -1;

            for (let i = 0; i < newData.columns.length; i++) {
              if (col.field_name == newData.columns[i].field_name) {
                index = i;
                break;
              }
            }

            if (index != -1) return fn(row, index);
          }

          if (formatString) {
            // {IPS} - <span style="background-color: red; font-weight: bold">{SoLan:0.00}</span>
            return formatString.replace(
              /\{([^:}]+)(:[^}]*)?\}/g,
              (all, title, format) => {
                let index = -1;
                for (let i = 0; i < newData.columns.length; i++) {
                  if (title == newData.columns[i].title) {
                    index = i;
                    break;
                  }
                }
                if (index === -1 || MethodService.isNoData(row[index]))
                  return "-";
                if (!format) return MethodService.htmlEntities(row[index]);
                let strFormat = format.slice(1);
                if (strFormat === "url") return encodeURIComponent(row[index]);
                if (strFormat === "raw") return row[index];

                return MethodService.htmlEntities(
                  moment(row[index] * 1000).format(strFormat)
                );
              }
            );
          }

          let index = -1;
          for (let i = 0; i < newData.columns.length; i++) {
            if (col.field_name == newData.columns[i].field_name) {
              index = i;
              break;
            }
          }

          if (index != -1) {
            if (col.type !== "rate") return row[index];

            if (row[index] == 1) return 1.5;
            if (row[index] == 5) return 4.5;
            return row[index];
          }

          let indexRow = -1;
          newData.columns.some((item, idx) => {
            if (item.field_name === col.field_name) {
              indexRow = idx;
              return true;
            }
            return false;
          });
          if (indexRow !== -1) return row[indexRow];

          return undefined;
        });
      });
    }
    loading.value = false;
  }
);
</script>
<style scoped lang="scss">
.table-component {
  &__sort {
    margin-left: 3px;
    display: flex;
    flex-direction: column;

    i {
      cursor: pointer;
      line-height: 5px;
      font-size: 16px;
      margin: 2px 0;
      transition: 0.2s;

      &:hover {
        color: #407bff;
      }
    }

    i.active {
      color: #407bff;
    }
  }
}

.textBold {
  font-weight: bold;
}

.textItalic {
  font-style: italic;
}

.textUnderline {
  text-decoration: underline;
}

.height100 {
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header img {
  margin-right: 10px;
  height: 16px;
  width: 16px;
}

.header-tooltip {
  color: #222222;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.table-body {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

:deep .el-scrollbar__wrap--hidden-default {
  max-height: 100% !important;
}

.height100 {
  height: 100%;
}

:deep .table-component .cell {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: unset;
}

:deep .table-component .caret-wrapper {
  top: 2px;
}

.value {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.noCenter {
  align-items: unset;
}
</style>
