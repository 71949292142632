<template>
  <div :id="'cem-card-item-inner-' + props.item.id" style="width: 100%"></div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, watch, ref, reactive } from "vue";
import MethodService from "@/service/MethodService";
import Data from "@/components/CardItemsConfig/dataFormCardItemsConfig";
import * as echarts from "echarts";
import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";
const colorDefault = reactive({
  value: MethodService.copyObject(Data.colorDefault),
});
const colorDefaultGradientBarChart = reactive({
  value: MethodService.copyObject(Data.colorDefaultGradientBarChart),
});
const emit = defineEmits(["showDialogDashboardPopup"]);
const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  dataChartOption: Object,
});
const chart_link = ref("");
const colorGradient = ref([]);
const chartTitle = ref();
const optionLegend = ref({});
const renderColorGradientBarChart = () => {
  let colorGradientBarChart = [];
  if (!colorGradient.value || colorGradient.value.length == 0) {
    colorGradientBarChart = colorDefault.value;
  } else {
    colorGradientBarChart = [];
    colorGradient.value.forEach((item, index) => {
      if (item.bg_color.color && !item.bg_color.to_color) {
        colorGradientBarChart.push(item.bg_color.color);
      } else if (item.bg_color.color && item.bg_color.to_color) {
        colorGradientBarChart.push(
          new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: colorGradient.value[index].bg_color.color,
            },
            {
              offset: 1,
              color: colorGradient.value[index].bg_color.to_color,
            },
          ])
        );
      }
    });
    colorDefaultGradientBarChart.value.forEach((item) => {
      colorGradientBarChart.push(
        new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: item[0],
          },
          {
            offset: 1,
            color: item[1],
          },
        ])
      );
    });
  }
  return colorGradientBarChart;
};
let dataPie = [];

const render = (data) => {
  if (!data || data.length == 0) return;

  data?.data.forEach((item, index) => {
    dataPie.push({
      value: item[1],
      name: item[0],
      link: props.dataChartOption
        ? props.dataChartOption.data_serials[index]?.popup?.popup_id ||
          props.dataChartOption.data_serials[index]?.link ||
          props.dataChartOption?.chart_link ||
          undefined
        : props.item.chart.data_serials[index]?.popup?.popup_id ||
          props.item.chart.data_serials[index]?.link ||
          props.item.chart?.chart_link ||
          undefined,
    });
  });

  const chartDom = document.getElementById(
    `cem-card-item-inner-${props.item.id}`
  );

  const myChart = echarts.init(chartDom);

  const option = {
    grid: {
      left: 40,
      top: chartTitle.value.show ? 40 : 20,
      right: 20,
      bottom: 20,
    },
    title: chartTitle.value.show
      ? {
          text: chartTitle.value.text,
          textStyle: {
            fontSize: chartTitle.value.textStyle.fontSize,
            color: chartTitle.value.textStyle.color,
          },
        }
      : null,
    tooltip: {
      trigger: "item",
      backgroundColor: "#1c1c1c",
      borderColor: "#1C1C1C",
      borderRadius: 14,
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
    },
    legend: {
      orient: optionLegend.value?.orient
        ? optionLegend.value.orient
        : "horizontal",
      type: optionLegend.value?.orient == "vertical" ? "scroll" : "",
      left: optionLegend.value.align?.horizontal || "center",
      top: optionLegend.value.align?.vertical || "bottom",
      textStyle: {
        color: "#1c1c1c",
      },
    },
    series: [
      {
        name: data.columns[1].title,
        type: "pie",
        radius: ["40%", "70%"],
        center: ["50%", "50%"],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 2,
        },
        label: {
          show: false,
          position: "center",
        },
        data: dataPie,
        color: renderColorGradientBarChart(),
      },
    ],
    // color: colorChart.value.length > 0 ? colorChart.value : colorDefault.value,
  };

  myChart.setOption(option);
  myChart.getZr().on("dblclick", function (event) {
    if (!event.target) if (chart_link.value) window.open(chart_link.value);
  });
  myChart.on("dblclick", function (params) {
    if (params?.data?.link) {
      let regex = /^[0-9a-fA-F]{24}$/;
      if (params?.data?.link.match(regex)) {
        // có cấu hình link dashboard popup
        Promise.all([MethodService.isDashboardPopup(props.item?.card_id)]).then(
          (result) => {
            if (!result || result[0]) return;
            emit(
              "showDialogDashboardPopup",
              params?.data?.link,
              MethodService.renderLinkChart(
                params?.name,
                params?.seriesName,
                params?.data?.value,
                localStorage.getItem("urlOld")
                  ? localStorage.getItem("urlOld")
                  : location.href
              )
            );
          }
        );
      } else {
        if (params?.data?.link === chart_link.value) {
          window.open(params?.data?.link);
        } else
          window.open(
            MethodService.renderLinkChart(
              params?.name,
              params?.seriesName,
              params?.data?.value,
              params?.data?.link
            )
          );
      }
    }
  });
};

onMounted(() => {
  chartTitle.value = props.dataChartOption
    ? props.dataChartOption.title
    : props.item.chart.title;
  colorGradient.value = props.dataChartOption
    ? props.dataChartOption.data_serials
    : props.item.chart.data_serials;
  optionLegend.value = props.dataChartOption
    ? props.dataChartOption.legend
    : props.item.chart.legend;
  chart_link.value = props.dataChartOption
    ? props.dataChartOption.chart_link
    : props.item.chart.chart_link;

  render(props.itemData);
});
watch(
  () => props.itemData,
  (data) => render(data)
);
</script>
<style scoped></style>
