<template>
  <div :id="'cem-card-item-inner-' + props.item.id" style="width: 100%"></div>
</template>

<script setup>
import { defineEmits, defineProps, onMounted, ref, reactive, watch } from "vue";
import MethodService from "../../service/MethodService";
import Data from "../../components/CardItemsConfig/dataFormCardItemsConfig";
import * as echarts from "echarts";
import { Card_item } from "cem-probe-api";
import { GridStack } from "gridstack";
const colorDefault = reactive({
  value: MethodService.copyObject(Data.colorDefault),
});
const emit = defineEmits(["showDialogDashboardPopup"]);
const props = defineProps({
  item: Card_item,
  itemData: null,
  grid: GridStack,
  dataChartOption: Object,
});
const colorGradient = ref([]);
const rotateLabel = ref(0);
const chartTitle = ref();
const chartUI = reactive({
  smooth: false,
  borderRadius: [],
});
const chart_link = ref("");
const seriesData = ref([]);
const renderBorderRadius = () => {
  let border = [];
  switch (chartUI.borderRadius) {
    case "small":
      border = [4, 4, 4, 4];
      break;
    case "large":
      border = [10, 10, 0, 0];
      break;
    default:
      border = [0, 0, 0, 0];
  }
  return border;
};
const renderBorderColor = (index) => {
  let borderColor = null;
  if (colorGradient.value && colorGradient.value[index - 1]) {
    if (
      colorGradient.value[index - 1].line_color &&
      colorGradient.value[index - 1].line_color.length > 0
    ) {
      borderColor = colorGradient.value[index - 1].line_color;
    } else borderColor = "rgba(255,255,255,0)";
  } else borderColor = "rgba(255,255,255,0)";
  return borderColor;
};
const renderColorGradientBarChart = (index) => {
  let colorGradientBarChart = null;
  if (colorGradient.value && colorGradient.value.length > 1) {
    if (colorGradient.value[index - 1]) {
      if (
        colorGradient.value[index - 1].bg_color.to_color &&
        colorGradient.value[index - 1].bg_color.color &&
        colorGradient.value[index - 1].bg_color.to_color.length > 0
      ) {
        colorGradientBarChart = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: colorGradient.value[index - 1].bg_color.color,
          },
          {
            offset: 1,
            color: colorGradient.value[index - 1].bg_color.to_color,
          },
        ]);
      } else if (!colorGradient.value[index - 1].bg_color.color) {
        colorGradientBarChart = colorDefault.value[index];
      } else
        colorGradientBarChart = colorGradient.value[index - 1].bg_color.color;
    } else colorGradientBarChart = colorDefault.value[index - 1];
  } else if (colorGradient.value && colorGradient.value.length == 1) {
    if (colorGradient.value[index - 1]) {
      if (
        colorGradient.value[index - 1].bg_color.to_color &&
        colorGradient.value[index - 1].bg_color.color &&
        colorGradient.value[index - 1].bg_color.to_color.length > 0
      ) {
        colorGradientBarChart = new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: colorGradient.value[index - 1].bg_color.color,
          },
          {
            offset: 1,
            color: colorGradient.value[index - 1].bg_color.to_color,
          },
        ]);
      } else if (!colorGradient.value[index - 1].bg_color.color) {
        colorGradientBarChart = colorDefault.value[index - 1];
      } else
        colorGradientBarChart = colorGradient.value[index - 1].bg_color.color;
    } else colorGradientBarChart = colorDefault.value[index - 1];
  } else colorGradientBarChart = colorDefault.value[index - 1];

  return colorGradientBarChart;
};
const renderDataAllColumn = (data, number) => {
  seriesData.value = [];
  for (let i = 1; i < number; i++) {
    seriesData.value.push({
      name: data.columns[i]?.title,
      data: data.data.map((row) => row[i]),
      type: "bar",
      link: props.dataChartOption
        ? props.dataChartOption.data_serials[i - 1]?.popup?.popup_id ||
          props.dataChartOption.data_serials[i - 1]?.link ||
          props.dataChartOption?.chart_link ||
          undefined
        : props.item.chart.data_serials[i - 1]?.popup?.popup_id ||
          props.item.chart.data_serials[i - 1]?.link ||
          props.item.chart?.chart_link ||
          undefined,
      itemStyle: {
        borderRadius: renderBorderRadius(),
        color: renderColorGradientBarChart(i),
        borderColor: renderBorderColor(i),
      },
    });
  }
  return seriesData.value;
};
const render = (data) => {
  if (!data || data.length == 0) return;

  const chartDom = document.getElementById(
    `cem-card-item-inner-${props.item.id}`
  );
  const myChart = echarts.init(chartDom);

  const option = {
    grid: {
      left: "3%",
      top: chartTitle.value.show ? 40 : 20,
      right: 20,
      bottom: 20,
      containLabel: true,
    },
    title: chartTitle.value.show
      ? {
          text: chartTitle.value.text,
          textStyle: {
            fontSize: chartTitle.value.textStyle.fontSize,
            color: chartTitle.value.textStyle.color,
          },
        }
      : null,
    tooltip: {
      trigger: "axis",
      backgroundColor: "#1c1c1c",
      borderColor: "#1C1C1C",
      borderRadius: 14,
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
      axisPointer: {
        type: "shadow",
        label: {
          show: false,
          formatter: function (params) {
            return data.columns[0].title + ": " + params.value;
          },
        },
      },
    },
    xAxis: {
      type: "category",
      data: data.data.map((col) => col[0]),
      axisLabel: {
        rotate: rotateLabel.value,
        color: "#1c1c1c",
      },
    },
    yAxis: {
      type: "value",
      axisLabel: {
        color: "#1c1c1c",
      },
      min: props.dataChartOption
        ? props.dataChartOption.y_axis.min
        : props.item.chart.y_axis.min,
      max: props.dataChartOption
        ? props.dataChartOption.y_axis.max
        : props.item.chart.y_axis.max,
      splitLine: {
        lineStyle: {
          color: "#D9E5FF",
          type: "dashed",
          width: 1.5,
        },
      },
    },
    series: renderDataAllColumn(data, data.columns.length),
  };

  myChart.setOption(option);
  myChart.getZr().on("dblclick", function (event) {
    if (!event.target) if (chart_link.value) window.open(chart_link.value);
  });
  myChart.on("dblclick", function (params) {
    let link = seriesData.value.filter(
      (item) => item.name === params.seriesName
    )[0]?.link;
    if (link) {
      let regex = /^[0-9a-fA-F]{24}$/;
      if (link.match(regex)) {
        // có cấu hình link dashboard popup
        Promise.all([MethodService.isDashboardPopup(props.item?.card_id)]).then(
          (result) => {
            if (!result || result[0]) return;
            emit(
              "showDialogDashboardPopup",
              link,
              MethodService.renderLinkChart(
                params?.name,
                params?.seriesName,
                params?.data,
                localStorage.getItem("urlOld")
                  ? localStorage.getItem("urlOld")
                  : location.href
              )
            );
          }
        );
      } else {
        if (link === chart_link.value) {
          window.open(link);
        } else
          window.open(
            MethodService.renderLinkChart(
              params?.name,
              params?.seriesName,
              params?.data,
              link
            )
          );
      }
    }
  });
};

onMounted(() => {
  chartUI.borderRadius = props.dataChartOption
    ? props.dataChartOption.borderRadiusType
    : props.item.chart.borderRadiusType;
  chartTitle.value = props.dataChartOption
    ? props.dataChartOption.title
    : props.item.chart.title;
  colorGradient.value = props.dataChartOption
    ? props.dataChartOption.data_serials
    : props.item.chart.data_serials;
  rotateLabel.value = props.dataChartOption
    ? props.dataChartOption.y_axis.axisLabel?.rotate ?? 0
    : props.item.chart.y_axis.axisLabel?.rotate ?? 0;
  chart_link.value = props.dataChartOption
    ? props.dataChartOption.chart_link
    : props.item.chart.chart_link;
  render(props.itemData);
});
watch(
  () => props.itemData,
  (data) => {
    render(data);
  }
);
</script>
